/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Box, Icon, P1, Spacer, Stack, Switch, useConfig } from "@mailbrew/uikit";
import InfoButton from "components/InfoButton";

/**
 * 
 * @example
 * 
 * <SettingSwitchRow
      state={newsletter.show_headings}
      onChange={() =>
        dispatch(
          updateCurrentNewsletterShowHeadings(!newsletter.show_headings)
        )
      }
      copyOn="Show Headings"
      copyOff="Show Headings"
      iconOn="hamburger"
      iconOff="hamburger"
      color={config.colors.c3}
    />
 */

const SettingSwitchRow = (props) => {
  const {
    state,
    small,
    tooltip,
    reverseState,
    onChange,
    copy,
    copyOn,
    copyOff,
    height = "30px",
    icon: providedIcon,
    iconOn,
    iconOff,
    noPadding,
    ...otherProps
  } = props;

  const icon = iconOn || iconOff || providedIcon;

  const config = useConfig();

  return (
    <Box w="100%" {...otherProps}>
      <Stack w="100%" gap={2} height={height}>
        {icon && (
          <Icon
            size={17}
            name={iconOn && iconOff ? (state ? iconOn : iconOff) : icon}
            color={config.colors.c2}
            style={{
              flex: "0 0 auto",
            }}
          />
        )}
        <Stack style={{ flex: 1 }} align="left" height={height}>
          <P1 fontSize={small ? "14.5px" : undefined}>{state ? copyOn || copy : copyOff || copy}</P1>
          {tooltip && <InfoButton text={tooltip} />}
        </Stack>
        <Switch
          small={small}
          on={reverseState ? !state : state}
          onClick={() => onChange(!state)}
          style={{ flex: "0 0 auto" }}
        />
      </Stack>
      {!noPadding && <Spacer size={2} />}
    </Box>
  );
};

export default SettingSwitchRow;
