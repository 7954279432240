/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import {
  AnimatedContainer,
  Button,
  Expandable,
  Grid,
  H5,
  Icon,
  Input,
  MediaQuery,
  Modal,
  P2,
  SimpleDropDown,
  Spacer,
  Tabs,
  useBreakpoint,
  useConfig,
  useDebounce,
} from "@mailbrew/uikit";
import axios from "axios";
import SettingSwitchRow from "components/editor/SettingSwitchRow";
import copy from "copy-to-clipboard";
import { Fragment, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { Disable } from "react-disable";
import zIndexes from "zIndexes";
import CopyButton from "./CopyButton";

const EMBED_BASE_URL = "https://embed.mailbrew.com";

const IFRAME_MODE = "iframe";
const HTML_MODE = "html";
const NOTION_MODE = "notion";

const EmbedCodeModal = (props) => {
  const { newsletter, show, setShow } = props;

  const [embedMode, setEmbedMode] = useState(IFRAME_MODE);
  const noTransparency = embedMode === NOTION_MODE;

  const breakPoint = 750;

  const isMobile = useBreakpoint(breakPoint);
  const [optionsExpanded, setOptionsExpanded] = useState(false);

  const [embedHtmlCodeLoading, setEmbedHtmlCodeLoading] = useState(false);
  const [embedHtmlCodeCopied, setEmbedHtmlCodeCopied] = useState(false);

  const config = useConfig();

  const [description, setDescription] = useState("");
  const [cta, setCta] = useState("Subscribe");

  const [showUser, setShowUser] = useState(true);
  const [showTitle, setShowTitle] = useState(true);
  const [showPoweredBy, setShowPoweredBy] = useState(true);

  const [textColor, setTextColor] = useState("#262629");
  const [buttonColor, setButtonColor] = useState("#f75858");
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [transparent, setTransparent] = useState(false);
  const [invertWithDarkMode, setInvertWithDarkMode] = useState(false);

  const background = transparent && !noTransparency ? "transparent" : backgroundColor;

  const params = compileUrlParams({
    id: newsletter.share_id,
    showUser,
    showTitle,
    showPoweredBy,
    description,
    cta,
    buttonColor,
    backgroundColor: background,
    textColor,
    invertWithDarkMode: noTransparency ? false : invertWithDarkMode,
  });

  const embedUrl = useDebounce(`${EMBED_BASE_URL}/?` + params, 600);

  const borderStyle = transparent ? { border: "none" } : { border: `1px solid hsl(30, 15%, 93%)` };
  const baseHeight = 78;
  const addUser = showUser ? 30 : 0;
  const addTitle = showTitle ? 28 : 0;
  const addDescription = description ? 6 + Math.ceil(description.length / 50) * 19 : 0;
  const addPowered = showPoweredBy ? 22 : 0;
  const height = useDebounce(baseHeight + addUser + addTitle + addDescription + addPowered, 600);

  const iFrameReactStyle = {
    width: "360px",
    height: `${height}px`,
    background: background,
    borderRadius: "6px",
    position: "relative",
    overflow: "hidden",
    ...borderStyle,
  };

  const iFrameTitle = newsletter?.title ? newsletter.title + " Subscribe Form" : "Subscribe Form";

  const iFrameComponent = (
    <iframe
      id="mailbrew-embed-iframe"
      title={iFrameTitle}
      style={iFrameReactStyle}
      allowtransparency="true"
      src={embedUrl}
    />
  );

  const iFrameReactCode = `<iframe
  title="${iFrameTitle}"
  style={${JSON.stringify(iFrameReactStyle)}}
  allowtransparency={true}
  src="${embedUrl}"
/>`;

  const iFrameHtmlCode = `<iframe
  title="${iFrameTitle}"
  style="${styleString(iFrameReactStyle)}"
  allowtransparency="true"
  src="${embedUrl}"
/>`;

  const previewCSS = css`
    @media (min-width: ${breakPoint}px) {
      border-left: 1px dashed ${config.colors.c6};
      border-radius: 0 ${config.appearance.bigRadius} ${config.appearance.bigRadius} 0;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  async function handleCopyHtml() {
    setEmbedHtmlCodeLoading(true);
    const fullHtml = (await axios.get(EMBED_BASE_URL + "/api/embed_html?" + params)).data;
    copy(fullHtml);
    setEmbedHtmlCodeLoading(false);
    setEmbedHtmlCodeCopied(true);
    setTimeout(() => setEmbedHtmlCodeCopied(false), 1200);
  }

  return (
    <Modal
      width="880px"
      show={show}
      setShow={setShow}
      style={{ padding: 0 }}
      closeButton={{ ...config.Modal.closeButton, offset: 10 }}
      bottomSheet={isMobile}
      zIndex={zIndexes.embedCodeModal}
      bg={config.colors.bg1}
    >
      <Grid columns="340px 1fr" vAlign="stretch" gap={0} breakAt={breakPoint + "px"} overflow="visible">
        <div style={{ padding: config.Card.bigPadding }}>
          <P2 mb={1}>Description (optional)</P2>
          <Input
            name="embed-description"
            component="textarea"
            height="55px"
            autoComplete="off"
            type="text"
            placeholder="Subscribe to get the latest..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ width: "100%" }}
          />
          <P2 mt={3} mb={1}>
            Button
          </P2>
          <Input
            name="embed-cta"
            autoComplete="off"
            type="text"
            placeholder="Subscribe"
            value={cta}
            onChange={(e) => setCta(e.target.value)}
            style={{ width: "100%" }}
          />
          <Spacer size={3} />
          {isMobile && (
            <Button
              mb={1}
              w="100%"
              variant="white"
              icon="settingsAlt"
              onClick={() => setOptionsExpanded(!optionsExpanded)}
            >
              Options
            </Button>
          )}
          <Expandable
            mt={2}
            overflow={isMobile ? "hidden" : undefined}
            expanded={!isMobile || optionsExpanded}
            startAnimatingAfter={100}
          >
            <CustomSettingsSwitchRow
              copy="User"
              icon="userCircleBold"
              state={showUser}
              onChange={() => setShowUser(!showUser)}
            />
            <CustomSettingsSwitchRow
              copy="Title"
              icon="text"
              state={showTitle}
              onChange={() => setShowTitle(!showTitle)}
            />
            <CustomSettingsSwitchRow
              copy="Powered by Mailbrew"
              icon="lightning"
              state={showPoweredBy}
              onChange={() => setShowPoweredBy(!showPoweredBy)}
            />
            <Disable disabled={noTransparency}>
              <CustomSettingsSwitchRow
                copy="Transparent background"
                icon="colorPicker"
                state={transparent}
                onChange={() => setTransparent(!transparent)}
              />
              <Expandable expanded={transparent} startAnimatingAfter={100} spring={{ duration: 0.3, bounce: 0.05 }}>
                <CustomSettingsSwitchRow
                  copy="Invert colors on dark mode"
                  tooltip="Use this if your website becomes dark based on the system theme change."
                  icon="darkBold"
                  state={invertWithDarkMode}
                  onChange={() => setInvertWithDarkMode(!invertWithDarkMode)}
                />
              </Expandable>
            </Disable>
            <Grid columns="1fr 1fr 1fr" gap="5px" mt={2}>
              <ColorSetting label="Button">
                <ColorPicker color={buttonColor} setColor={setButtonColor} />
              </ColorSetting>
              <ColorSetting label="Text">
                <ColorPicker color={textColor} setColor={setTextColor} />
              </ColorSetting>
              <Disable disabled={transparent && !noTransparency}>
                <ColorSetting label="Back">
                  <ColorPicker color={backgroundColor} setColor={setBackgroundColor} />
                </ColorSetting>
              </Disable>
            </Grid>
          </Expandable>
          <Spacer size={3} />
          <Tabs
            activeTab={embedMode}
            tabs={[IFRAME_MODE, HTML_MODE, NOTION_MODE]}
            tabsNames={["iFrame", "HTML", "Notion & Other"]}
            onTabChange={(t) => setEmbedMode(t)}
          />
          <Spacer size={3} />
          <AnimatedContainer startAnimatingAfter={100}>
            {embedMode === IFRAME_MODE && (
              <Fragment>
                <Grid gap="0.2em">
                  <CopyButton
                    copyText={iFrameHtmlCode}
                    variant="white"
                    iconStroke="1.8px"
                    width="100%"
                    icon="html"
                    iconSize="1.2em"
                  >
                    HTML
                  </CopyButton>
                  <CopyButton
                    copyText={iFrameReactCode}
                    variant="white"
                    iconStroke="1.8px"
                    width="100%"
                    icon="react"
                    iconSize="1.2em"
                  >
                    React
                  </CopyButton>
                </Grid>
                <P2 align="center" mt={2} w="100%" maxW="250px" mx="auto">
                  This embed will always stay up to date with the latest improvements.
                </P2>
              </Fragment>
            )}
            {embedMode === HTML_MODE && (
              <Fragment>
                <Button
                  onClick={handleCopyHtml}
                  variant="white"
                  width="100%"
                  loading={embedHtmlCodeLoading}
                  icon={embedHtmlCodeCopied ? "checkmark" : "html"}
                  iconStroke="1.8px"
                  iconSize="1.2em"
                >
                  {embedHtmlCodeCopied ? "Copied" : "Copy Embed Code"}
                </Button>
                <P2 align="center" mt={2} w="100%" maxW="250px" mx="auto">
                  Simple and customizable HTML code you can use in your static site.
                </P2>
              </Fragment>
            )}
            {embedMode === NOTION_MODE && (
              <Fragment>
                <CopyButton
                  copyText={embedUrl}
                  variant="white"
                  width="100%"
                  iconStroke="1.8px"
                  icon="link"
                  iconSize="1.2em"
                >
                  Copy Embed Link
                </CopyButton>
                <P2 align="center" mt={2} w="100%" mx="auto">
                  Direct link to the embed. Paste in Notion and click "embed" to insert the form.
                </P2>
              </Fragment>
            )}
          </AnimatedContainer>
          <Spacer size={5} />
          <MediaQuery showBelow={breakPoint + "px"}>
            <H5 align="center" mt={2}>
              Embed preview:
            </H5>
          </MediaQuery>
        </div>
        <div css={previewCSS}>{iFrameComponent}</div>
      </Grid>
      {isMobile && <Spacer size={6} />}
    </Modal>
  );
};

const CustomSettingsSwitchRow = (props) => {
  return <SettingSwitchRow small height="24px" {...props} />;
};

const ColorSetting = ({ label, children }) => {
  return (
    <div>
      <P2 mb={1}>
        <Icon name="bucket" size="16px" offset="-2px" currentColor /> {label}
      </P2>
      {children}
    </div>
  );
};

const ColorPicker = ({ color, setColor }) => {
  return (
    <Fragment>
      <SimpleDropDown style={{ padding: "5px" }} content={() => <HexColorPicker color={color} onChange={setColor} />}>
        <Input
          wrapperStyle={{ height: "32px" }}
          width="100%"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          placeholder="Hex Code"
          style={{ fontSize: "14px" }}
        />
      </SimpleDropDown>
    </Fragment>
  );
};

function styleString(style) {
  return Object.entries(style)
    .map(([k, v]) => {
      const dashedK = k.replace(/[A-Z]/g, (m) => "-" + m.toLowerCase());
      return `${dashedK}:${v}`;
    })
    .join(";");
}

function compileUrlParams(providedParams) {
  const cleanParams = {};
  for (let key in providedParams) {
    const value = providedParams[key];
    if (typeof value === "string" && value.length === 0) {
      continue;
    }
    cleanParams[key] = value;
  }
  const params = new URLSearchParams(cleanParams);
  return params.toString();
}

export default EmbedCodeModal;
