import { Icon, P2, Tooltip, useConfig } from "@mailbrew/uikit";

const InfoButton = (props) => {
  const {
    icon = "infoTiny",
    text,
    title,
    description,
    iconColor,
    size = "16px",
    interactive = true,
    onClick,
    href,
    children,
    style,
  } = props;

  const config = useConfig();

  return children ? (
    <P2 onClick={onClick} style={{ cursor: onClick || href ? "pointer" : "default" }}>
      {children}{" "}
      <Tooltip
        size="regular"
        interactive={interactive}
        arrow
        title={text || title}
        description={description}
        maxWidth={280}
        style={style}
      >
        <Icon onClick={onClick} offset="-2px" name={icon} size={size} color={iconColor ?? config.colors.c4} />
      </Tooltip>
    </P2>
  ) : (
    <Tooltip
      size="regular"
      interactive={interactive}
      arrow
      title={text || title}
      description={description}
      maxWidth={280}
      style={style}
    >
      <Icon
        onClick={onClick}
        style={{ cursor: onClick || href ? "pointer" : "default" }}
        offset="-2px"
        name={icon}
        size={size}
        color={iconColor ?? config.colors.c4}
      />
    </Tooltip>
  );
};

export default InfoButton;
