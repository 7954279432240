import {
  Button,
  Card,
  H3,
  H4,
  Input,
  Modal,
  notif,
  P1,
  P2,
  Section,
  Spacer,
  Stack,
  Table,
  Td,
  Th,
  Tr,
  useConfig,
} from "@mailbrew/uikit";
import CheckboxRow from "components/CheckboxRow";
import EmbedCodeModal from "components/EmbedCodeModal";
import InfoButton from "components/InfoButton";
import Page from "components/Page";
import StyledA from "components/StyledA";
import api from "dependencies/api";
import { useFormik } from "formik";
import loggedInPage from "hoc/loggedInPage";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentNewsletterSelector, fetchNewsletter, updateNewsletterField } from "reducers/newslettersReducer";
import useSWR, { mutate } from "swr";
import { range } from "utils/array";
import { fromNow } from "utils/formatDate";
import kFormatter from "utils/kFormatter";
import { pluralize } from "utils/pluralize";
import * as yup from "yup";
const FileDownload = require("js-file-download");

function ManageSubscribersPage() {
  const dispatch = useDispatch();
  const router = useRouter();
  const brewId = router.query.brewId;

  useEffect(() => {
    if (brewId) dispatch(fetchNewsletter(brewId));
  }, [dispatch, brewId]);

  return (
    <Page title="Manage subscribers" showMonetizationBanner>
      <ManageSubscribersUI />
    </Page>
  );
}

export default loggedInPage(ManageSubscribersPage);

export const ManageSubscribersUI = ({ newsletter: providedNewsletter }) => {
  const config = useConfig();
  const dispatch = useDispatch();

  /** Global state */
  const currentNewsletter = useSelector(currentNewsletterSelector);

  const newsletter = providedNewsletter ?? currentNewsletter;

  /** State */
  const [addSubscribersModalShown, setAddSubscribersModalShown] = useState(false);
  const [importSubscriberModalShown, setImportSubscriberModalShown] = useState(false);
  const [embedCodeModalShown, setEmbedCodeModalShown] = useState(false);
  const [exporting, setExporting] = useState(false);

  /** Effects */
  const { data: liveSubscribersCount } = useSWR(newsletter && `/newsletters/${newsletter.id}/subscribers/`, {
    revalidateOnFocus: true,
  });
  const subscribersCount = liveSubscribersCount?.subscribers ?? newsletter?.subscribers_count;

  /** Handlers */
  const handleDelete = async (subscriber, reloadCurrentPage) => {
    try {
      await api.delete(newsletterSubscribersURL() + subscriber.id + "/");
      reloadCurrentPage();
      mutate(`/newsletters/${newsletter.id}/subscribers/`);
    } catch (err) {
      notif.error(err?.response.data.detail || "Error while deleting this user");
    }
  };
  const handleMakePublic = () => {
    dispatch(updateNewsletterField("public", true));
  };
  const handleClickExport = () => {
    setExporting(true);
    api
      .get(`/newsletters/${newsletter.id}/export_subscribers/`)
      .then((res) => {
        FileDownload(res.data, "subscribers.csv");
      })
      .catch((error) => {
        if (error.response?.data?.detail) notif.error(error.response?.data?.detail);
        // eslint-disable-next-line no-console
        console.log(error);
      })
      .finally(() => {
        setExporting(false);
      });
  };

  if (!newsletter) return null;

  return (
    <Section width="60em" first>
      <H3 mb={4} align="center">
        "{newsletter.title}" Subscribers
      </H3>
      <Stack align="center" gap={1}>
        <Button variant="white" icon="upload" onClick={() => setImportSubscriberModalShown(true)}>
          Import
        </Button>
        <Button
          variant="white"
          icon="download"
          onClick={handleClickExport}
          loading={exporting}
          disabled={subscribersCount === 0}
        >
          Export
        </Button>
        <Button icon="plus" onClick={() => setAddSubscribersModalShown(true)}>
          Add
        </Button>
      </Stack>

      <Spacer />

      <P1>
        Your brew <strong>{newsletter.title}</strong> has{" "}
        <strong>
          {kFormatter(subscribersCount)} {pluralize("subscriber", subscribersCount)}
        </strong>
        .
      </P1>
      {newsletter.public && (
        <P1 mt={1}>
          Share this brew <Link href={`/${newsletter.share_url}`}>public page</Link> to let anyone subscribe or{" "}
          <span
            style={{ color: config.colors.accent1, cursor: "pointer" }}
            onClick={() => setEmbedCodeModalShown(true)}
          >
            embed a subscribe form
          </span>{" "}
          on your website.
        </P1>
      )}
      {!newsletter.public && (
        <P1>
          <span style={{ color: config.colors.accent1, cursor: "pointer" }} onClick={handleMakePublic}>
            Enable a public
          </span>{" "}
          page to let anyone subscribe, or add a subscriber manually.
        </P1>
      )}

      <Spacer size="s" />

      <Pagination initialPageURL={newsletterSubscribersURL(newsletter.id)} pageSize={10}>
        {({ loading, results, isEmpty, error, forceReload, reloadCurrentPage }) => {
          return (
            <Fragment>
              {(() => {
                if (loading)
                  return (
                    <SubscribersTable>
                      {range(10).map((index) => (
                        <SubscriberRow filler="..." key={index} />
                      ))}
                    </SubscribersTable>
                  );

                if (isEmpty)
                  return (
                    <SubscribersTable>
                      <SubscriberRow filler="No subscribers" />
                    </SubscribersTable>
                  );

                if (error)
                  return (
                    <Card center inline width="100%" minHeight="10em">
                      <Stack vertical align="center" w="100%">
                        <P1 align="center" color={config.colors.error}>
                          Oops! There's a problem.
                        </P1>
                        <P2 align="center" color={config.colors.error}>
                          {error?.response?.data.detail || error?.toString()}
                        </P2>
                        <StyledA
                          mt={2}
                          variant={["white", "small"]}
                          icon="emailBold"
                          href="mailto:support@mailbrew.com"
                        >
                          Get Help
                        </StyledA>
                      </Stack>
                    </Card>
                  );

                return (
                  <SubscribersTable>
                    {results.map((subscriber) => (
                      <SubscriberRow
                        subscriber={subscriber}
                        key={`${subscriber.id}${subscriber.status}`}
                        onDeleteClick={(subscriber) => handleDelete(subscriber, reloadCurrentPage)}
                      />
                    ))}
                  </SubscribersTable>
                );
              })()}
              <AddSubscribersModal
                newsletterID={newsletter.id}
                show={addSubscribersModalShown}
                setShow={setAddSubscribersModalShown}
                onSubscriberAdded={() => forceReload()}
              />
              <ImportSubscribersModal
                newsletterID={newsletter.id}
                show={importSubscriberModalShown}
                setShow={setImportSubscriberModalShown}
                onImportCompleted={() => {
                  forceReload();
                  mutate(`/newsletters/${newsletter.id}/subscribers/`);
                }}
              />
            </Fragment>
          );
        }}
      </Pagination>
      <EmbedCodeModal show={embedCodeModalShown} setShow={setEmbedCodeModalShown} newsletter={newsletter} />
    </Section>
  );
};

const ImportSubscribersModal = ({ show, setShow, newsletterID, onImportCompleted }) => {
  const config = useConfig();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showFileExampleModal, setShowFileExampleModal] = useState(false);

  useEffect(() => {
    if (!show) {
      setErrorMessage(null);
      setSuccessMessage(null);
      setFile(null);
    }
  }, [show]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    setErrorMessage(null);
    setSuccessMessage(null);

    if (files.length === 1) {
      setFile(files[0]);
    } else {
      alert("Please select just one CSV file.");
      setFile(null);
    }
  };

  const handleUpload = () => {
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let formData = new FormData();
    formData.append("csv", file);

    api
      .post(`newsletters/${newsletterID}/import_subscribers/`, formData)
      .then((res) => {
        setSuccessMessage(res.data.message);
        onImportCompleted();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setErrorMessage(err.response?.data?.detail);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      <Modal show={show} setShow={setShow}>
        <H4 align="center" mb={2}>
          Import Subscribers
        </H4>
        <P1 mb={4}>
          Import your subscribers from a{" "}
          <span
            style={{ cursor: "pointer", color: config.colors.accent1 }}
            onClick={() => setShowFileExampleModal(true)}
          >
            CSV file
          </span>
          .
        </P1>
        <input disabled={loading} type="file" onChange={handleFileChange} />
        {errorMessage && (
          <P2 mt={2} color={(c) => c.colors.error}>
            {errorMessage}
          </P2>
        )}
        {successMessage && (
          <P2 mt={2} color={(c) => c.colors.success}>
            {successMessage}
          </P2>
        )}
        {!successMessage && (
          <Button mt={4} disabled={!file} loading={loading} onClick={handleUpload}>
            Import
          </Button>
        )}
        {successMessage && (
          <Button mt={4} onClick={() => setShow(false)}>
            Done
          </Button>
        )}
      </Modal>
      <Modal show={showFileExampleModal} setShow={setShowFileExampleModal}>
        <H4 align="center" mb={2}>
          Sample CSV
        </H4>
        <P1 mb={2}>
          The first row of the file must contain the name of the fields. <code>email</code> is the only required field:
        </P1>
        <pre>{"email,name\nhello@mailbrew.com,Mailbrew\nhello@francescodilorenzo.com,Francesco"}</pre>
        <Button mt={4} onClick={() => setShowFileExampleModal(false)}>
          Got it
        </Button>
      </Modal>
    </Fragment>
  );
};

const SubscribersTable = ({ children }) => {
  return (
    <Table width="100%">
      <Tr header>
        <Th>Email</Th>
        <Th width="10em">Subscribed</Th>
        <Th width="10em">Status</Th>
        <Th />
      </Tr>
      {children}
    </Table>
  );
};

const SubscriberRow = ({ subscriber, filler, onDeleteClick }) => {
  const [actionInProgress, setActionInProgress] = useState(false);

  const handleDelete = () => {
    setActionInProgress(true);
    onDeleteClick(subscriber);
  };

  if (subscriber) {
    return (
      <Tr>
        <Td>{subscriber.email}</Td>
        <Td>{fromNow(subscriber.created_on)}</Td>
        <Td>
          {subscriberStatuses[subscriber.status]}{" "}
          {subscriber.status === 0 && (
            <InfoButton text="We're waiting for this user to click on the confirmation email." />
          )}
          {subscriber.status === 3 && (
            <InfoButton text="The email provider of this subscriber bounced the message 3 times, so we are no longer sending messages to this subscriber." />
          )}
          {subscriber.status === 4 && (
            <InfoButton text="The email provider of this subscriber hard-bounced the message, so we are no longer sending messages to this subscriber." />
          )}
          {subscriber.status === 5 && (
            <InfoButton text="This subscriber reported the message as spam, so we are no longer sending messages to this subscriber." />
          )}
        </Td>
        <Td align="right">
          <Button
            variant={["small", "white"]}
            height="30px"
            onClick={handleDelete}
            disabled={actionInProgress}
            loading={actionInProgress}
          >
            Delete
          </Button>
        </Td>
      </Tr>
    );
  } else {
    return (
      <Tr>
        <Td>{filler}</Td>
        <Td />
        <Td />
        <Td />
      </Tr>
    );
  }
};

const subscriberStatuses = {
  0: "Pending",
  1: "Subscribed",
  2: "Unsubscribed",
  3: "Soft Bounce",
  4: "Hard Bounce",
  5: "Spam Complaint",
};

const Pagination = ({ initialPageURL, pageSize = 3, children }) => {
  /** State */
  const [currentPageURL, setCurrentPageURL] = useState(initialPageURL);

  /** Effects */
  const { data, error } = useSWR(currentPageURL + `&size=${pageSize}`, {
    dedupingInterval: 0,
    revalidateOnFocus: true,
  });

  /** Computed state */
  const prevEnabled = data?.previous;
  const nextEnabled = data?.next;
  const showButtons = data?.previous || data?.next;

  const childrenProps = () => ({
    loading: !error && !data,
    results: data?.results,
    error: error,
    isEmpty: data?.results.length === 0,
    forceReload: handleForceReload,
    reloadCurrentPage: handleReloadCurrentPage,
  });

  /** Handlers */
  const handleNewerClick = () => {
    setCurrentPageURL(data.next);
  };
  const handleOlderClick = () => {
    setCurrentPageURL(data.previous);
  };
  const handleForceReload = () => {
    setCurrentPageURL(initialPageURL);
    mutate(initialPageURL + `&size=${pageSize}`);
  };
  const handleReloadCurrentPage = () => {
    mutate(currentPageURL + `&size=${pageSize}`);
  };

  return (
    <Fragment>
      {children(childrenProps())}
      {showButtons && (
        <Fragment>
          <Spacer size="s" />
          <Stack align="center">
            <Button variant="white" onClick={handleOlderClick} disabled={!prevEnabled}>
              Newer
            </Button>
            <Button variant="white" onClick={handleNewerClick} disabled={!nextEnabled}>
              Older
            </Button>
          </Stack>
        </Fragment>
      )}
    </Fragment>
  );
};

export const AddSubscribersModal = ({ show, setShow, newsletterID, onSubscriberAdded }) => {
  /** State */
  const [authorized, setAuthorized] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  /** Handlers */
  const handleSubmit = async (values) => {
    if (!authorized) {
      setSubmitError({ detail: "You need to confirm the checkbox below." });
      return;
    }

    setSubmitError(null);

    try {
      await api.post(newsletterSubscribersURL(), {
        email: values.subscriber_address,
        newsletter: newsletterID,
        status: 1,
      }); // prettier-ignore
      setShow(false);
      onSubscriberAdded && onSubscriberAdded();
      notif.success("Subscribed added");
      mutate(`/newsletters/${newsletterID}/subscribers/`);
    } catch (err) {
      setSubmitError(err?.response.data);
    }
  };

  /** Effects */
  const formik = useFormik({
    initialValues: { subscriber_address: "" },
    validationSchema: yup.object().shape({
      subscriber_address: yup.string().email("Please enter a valid email").required("Please enter a valid email"), // prettier-ignore
    }),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (show) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  /** Computed state */
  const emailFieldErrors = submitError
    ? (submitError.non_field_errors && submitError.non_field_errors[0]) ||
      (submitError.email && submitError.email[0]) ||
      submitError.detail
    : formik.touched.email && !!formik.errors.email;

  return (
    <Modal show={show} setShow={setShow} width="28em">
      <H4 align="center" mb={1}>
        Add Subscriber
      </H4>
      <P1 mb={3}>Subscribers receive new issues with you.</P1>
      <form onSubmit={formik.handleSubmit}>
        <Stack noWrap vAlign="top">
          <Input
            autoFocus={show}
            name="subscriber_address"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={emailFieldErrors}
          />
          <Button noStretch submit>
            Subscribe
          </Button>
        </Stack>
        <Spacer size={4} />
        <CheckboxRow onChange={(value) => setAuthorized(value)}>
          This person authorized me to subscribe them
        </CheckboxRow>
        <Spacer size="xs" />
      </form>
    </Modal>
  );
};

const newsletterSubscribersURL = (newsletterID) =>
  newsletterID ? `/brew_subscriptions/?newsletter=${newsletterID}` : "/brew_subscriptions/";
